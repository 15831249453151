<template>
    <div :is="!state==true?layout:'div'">
        <div class="d-flex justify-space-between align-center text_color">
            <div class="d-flex justify-start align-center ml-4 ">
                 <span class="pr-1">{{$t('flight.switch_view')}}: </span>
                <v-radio-group @change="getGanttConfigByZoomValue(radioGroup)" v-model="radioGroup"  row>
                <v-radio :label='$t("flight.year")' value="year"></v-radio>
                <v-radio :label='$t("flight.month")' value="month"></v-radio>
                <v-radio :label='$t("flight.day")'  value="day"></v-radio>
                <v-radio :label='$t("flight.hour")' value="hour"></v-radio>
                <v-radio :label='$t("flight.minute")' value="minute"></v-radio>
                </v-radio-group>
            </div>
          <div v-if="!state==true">
            <v-btn  style="margin: 10px" color="accent" @click="submit" small>{{$t('flight.confirm')}}</v-btn>
            <v-btn  style="margin: 10px;margin-right:30px" @click="cancle"  color="accent" small>{{$t('flight.cancel')}}</v-btn>
          </div>
        </div>
        
        <div id="gantt" :style='!state==true?"width:100%;overflow:auto; height:calc(100vh - 170px)":"width:100%; height:calc(100% - 70px)"'></div>
    </div>
</template>
<script>
    import gantt from 'dhtmlx-gantt'  // 引入模块
    import 'dhtmlx-gantt/codebase/dhtmlxgantt.css'
    import {get_gantt_data,editScheduler} from "../../../api/airport_security";

    export default {
        props:{
        state: {type: Boolean},
        start_time:{type:String},
        end_time:{type:String}
        },
        data: () => ({
            data: null,
            layout: null,
            list:[],
            changList:[],
            result:null,
            flag:false,
            group:[],
            radioGroup:'hour',
            English:{  
                labels:{
                    column_text: "Name",
                    column_start_date: "Start time",
                    column_end_date: "End time",
                    }},
            Chinese: {
                labels:{
                    column_text: "名称",
                    column_start_date: "开始时间",
                    column_end_date: "结束时间",
                    }}
        }),
        methods: {
                getGanttConfigByZoomValue (value) {
                switch (value) {
                     case 'minute':
                        gantt.config.scales = [
                        {unit: "day", step: 1, format: "%Y-%m-%d "},
                        {unit: "minute", step:1, format: "%H:%i" },
                        ];
                        gantt.config.scale_height = 27;
                        break
                        case 'hour':
                        gantt.config.scales = [
                        {unit: "day", step: 1, format: "%Y-%m-%d "},
                        {unit: "hour", step:1, format: "%H" }, 
                        ];
                         gantt.config.scale_height = 27;
                        break
                        case 'day':
                        gantt.config.scales = [
                        {unit: "year", step: 1, format: "%Y"},
                        {unit: "day", step: 1, format: "%d %M"}];
                        gantt.config.scale_height = 27;
                        break
                        case "month":
                        gantt.config.scales = [
                        {unit: "month", step: 1, format: "%F, %Y"},
                        ];
                        gantt.config.scale_height = 50;
                        break;
                        case "year":
                        gantt.config.scales = [
                            {unit: "year", step: 1, format: "%Y"},
                        ];
                        gantt.config.scale_height = 50;
                        break;
                }
                  gantt.render();
                },
                    async  submit(){
                if(this.changList.length&&this.flag){
                    var count=0
                    this.boolean=true;
                    // for(let i=0;i<this.changList.length;i++){
                    //  if(this.changList[i].parent==0){
                    //     var end_point_list=[]
                    //     for(let j=0;j<this.changList.length;j++){
                    //     if(this.changList[i].id==this.changList[j].parent){
                    //        end_point_list.push({ 
                    //             id: this.changList[j].id,
                    //             support_vehicle_path_scheduling_id: this.changList[j].parent,
                    //             end_point:this.changList[j].end_point,
                    //             await_time: this.changList[j].await_time})
                    //     }
                    // }
                    // this.group.push({
                    // id:this.changList[i].id,
                    // start_time:this.formateDate(this.changList[i].start_date),
                    // end_time:this.formateDate(this.changList[i].end_date),
                    // end_point_list:end_point_list
                    // })
                    //  }
                    // }
                    //  for(let i=0;i<this.group.length;i++){
                     for(let i=0;i<this.changList.length;i++){
                    if(this.boolean){
                    // var item=this.group[i]
                    var item=this.changList[i]
                    item.start_time=this.formateDate(item.start_date)
                    item.end_time=this.formateDate(item.end_date)
                    await  editScheduler(item).then(res => {
                    count++
                    // if(count==this.group.length){
                    if(count==this.changList.length){
                    this.$toast({
                    title:  this.$t('success'),
                    message: this.$t('flight.edit_success')
                    })
                     this.result=JSON.parse(JSON.stringify(this.list))
                    gantt.render();
                    }
                    }).catch(e=>{
                        this.$toast({
                        title:this.$t('error'),
                        message:this.$t('flight.edit_error'),
                        type: 'warning'
                })
                    this.boolean=false
                         })
                       }
                }
                this.flag=false
                }else{
                    this.$toast({
                        title:this.$t('error'),
                        message:this.$t('flight.edit_none'),
                        type: 'warning'
                })
                }
            },
            cancle(){
                if(this.flag){
                let regain=JSON.parse(JSON.stringify(this.result))
                    regain.forEach((item,index)=>{
                    regain[index].start_date=new Date(item.start_date)
                    if(item.end_date){
                    regain[index].end_date=new Date(item.end_date)
                    }
                })
                this.tasks = {
                data: regain
            };
            gantt.parse(this.tasks);
            this.flag=false
            }

            },
            formateDate(datetime) {
                function addDateZero(num) {
                    return num < 10 ? "0" + num : num;
                }
                let d = new Date(datetime);
                let formatdatetime =
                    d.getFullYear() +
                    "-" +
                    addDateZero(d.getMonth() + 1) +
                    "-" +
                    addDateZero(d.getDate()) +
                    " " +
                    addDateZero(d.getHours()) +
                    ":" +
                    addDateZero(d.getMinutes()) +
                    ":" +
                    addDateZero(d.getSeconds());
                return formatdatetime;
            },
            init() {
                // gantt.clearAll();
                var ganttdiv=document.getElementById('gantt')
                // 处理数据
                // const data_res=JSON.parse(JSON.stringify(this.data.results))
                this.data.results.forEach(item => {
                    item.render="split",
                    item.type="project"
                    item.start_date =new Date(item.start_date*1000)
                    item.end_date = new Date(item.end_date * 1000)
                    this.list.push(item)
                    if(item.end_point_list.length){
                        item.end_point_list.forEach((item1,index)=>{
                        item1.start_date=item.start_date
                        item1.text=item1.end_point
                        item1.duration=item1.await_time
                        item1.index=index
                        item1.parent=item1.support_vehicle_path_scheduling_id
                        this.list.push(item1)
                    })
                    }
                }) 
                const tasks = {
                    data: this.list
                };
                this.result=JSON.parse(JSON.stringify(this.list))
                gantt.config.open_split_tasks = true;
                gantt.config.date_format = "%Y-%m-%d %H:%i";//设置数据中的时间格式，对应start_date格式
                 // 语言
                 if (this.$i18n.locale === 'zh') {
                   gantt.i18n.setLocale(this.Chinese)
                } else if (this.$i18n.locale === 'en') {
                    gantt.i18n.setLocale(this.English)
                }
                gantt.config.columns = [//设置列
                    {name: "text", width: "*", align: "center", tree: true},
                    {name: "start_date",  width: "*", align: "center"},
                    {name: "end_date", width: "*", align: "center"},
                ];
                // 修改默认图标
                var father_icon=require('../../../assets/car.png');
                gantt.templates.grid_folder = function(item) {
                return `<div class='gantt_tree_icon gantt_folder_' style='background:url(${father_icon});background-size:75% 60%;background-position:50% 50%; background-repeat:no-repeat'></div>`;
                };
                var icon = require('../../../assets/stand.png');
                gantt.templates.grid_file = function (item) {
                    return `<div class='gantt_tree_icon' style='background:url(${icon});background-size:90% 70%;background-position:50% 50%; background-repeat:no-repeat'></div>`;
                };
                 gantt.templates.task_text=function(start, end, task){return task.text;};
                    if(this.state){
                    gantt.config.readonly = true
                    gantt.config.start_date = new Date(this.start_time);
                    gantt.config.end_date = new Date(this.end_time);
                }else{
                    gantt.config.readonly = false
                    gantt.config.start_date = new Date(this.data.start_time);//时间刻度的开始时间
                    gantt.config.end_date = new Date(this.data.end_time);//时间刻度的结束时间
                }
                gantt.config.duration_unit = "minute";
                gantt.config.time_step = 1;
                gantt.config.duration_step = 1;
                gantt.config.min_duration = 1*60; // (1min)
                gantt.config.drag_progress = false;
                gantt.locale.labels.section_start_date = "start_date";
                gantt.locale.labels.section_end_date = "end_date";
                gantt.config.lightbox.sections = [
                {name: "start_date",map_to: "start_date",type: "duration",time_format: ["%Y", "%m", "%d", "%H:%i"],single_date:true},
                {name: "end_date",map_to: "end_date",type: "duration",time_format: ["%Y", "%m", "%d", "%H:%i"],single_date:true},
                ];
                gantt.plugins({
                    tooltip: true,//鼠标划过任务是否显示明细
                    auto_scheduling: true,//根据任务之间的关系自动安排任务
                    multiselect: true, //为任务激活多任务选择
                    marker: true  //标记
                });
                 gantt.templates.tooltip_date_format=function (date){
                var formatFunc = gantt.date.date_to_str("%Y-%m-%d %H:%i");
                return formatFunc(date);
                 };
              
                // gantt.templates.tooltip_text = function (start, end, task) {
                //     if(task.await_time){
                //      return '<b>停靠机位:</b> ' + task.text + '<br/><b>出发时间:</b> ' + gantt.templates.tooltip_date_format(start)
                //      + '<br/><b>结束时间:</b> ' + gantt.templates.tooltip_date_format(end)
                //     }
                // }
                gantt.config.auto_scheduling = true;
                gantt.config.drag_links = false; //禁止拖动连接
                    gantt.attachEvent("onMouseMove", function (t,e){
                     gantt.templates.tooltip_text = function (start, end, task) {
                    if(task.await_time&&(e.clientX-ganttdiv.getBoundingClientRect().left)> gantt.config.grid_width){
                     return '<b>停靠机位:</b> ' + task.text + '<br/><b>出发时间:</b> ' + gantt.templates.tooltip_date_format(start)
                     + '<br/><b>结束时间:</b> ' + gantt.templates.tooltip_date_format(end)
                    }else if(!task.await_time&&(e.clientX-ganttdiv.getBoundingClientRect().left)> gantt.config.grid_width){
                          return '<b>保障车名称:</b> ' + task.text + '<br/><b>出发时间:</b> ' + gantt.templates.tooltip_date_format(start)
                     + '<br/><b>结束时间:</b> ' + gantt.templates.tooltip_date_format(end)
                    }
                    }
                    });
                // gantt.config.order_branch = "marker";
                // gantt.config.order_branch_free = true;
                gantt.config.open_tree_initially = true;
                gantt.config.autoscroll = true;
                gantt.config.grid_width=340
                this.getGanttConfigByZoomValue('hour')
             
                gantt.init("gantt");
                gantt.clearAll()
                gantt.parse(tasks);
                // gantt.render()
                //      // 拖动左侧任务栏
                // var old_parent, newParent;
                // gantt.attachEvent("onRowDragStart", function(id, target, e) {
                // old_parent = gantt.getTask(id).parent;
                // return true;
                // });
                // gantt.attachEvent("onBeforeRowDragEnd", (id, parent, tindex)=>{
                // var getTask1 = gantt.getTask(id);
                // console.log(getTask1,"getTask");
                // newParent = getTask1.parent;  
                // gantt.moveTask(id,tindex,newParent);
                // gantt.updateTask(getTask1.id);
                // this.flag=true
                // this.changList=tasks.data
                // return true;
                // });
                  // 拖拽之后修改数据
                gantt.attachEvent("onAfterTaskDrag", (id,mode,e)=>{
                var middle={}
                this.flag=true
                let getTask=gantt.getTask(id)
                if(getTask.end_point){
                    this.data.results.forEach(item=>{
                    if(item.id==getTask.parent){
                    middle=item
                    middle.end_point_list[getTask.index]=JSON.parse(JSON.stringify(getTask))
                    middle.end_point_list[getTask.index].await_time=(getTask.end_date.getTime()-getTask.start_date.getTime())/1000/60
                    }
                    })
                }else{
                    middle=getTask
                }
                if(this.changList.length){
                var result1 = this.changList.some(item=>{if(item.id==middle.id) return true})
                if(result1){
                this.changList.forEach((item,index)=>{
                     if(item.id==middle.id){
                        this.changList[index]=JSON.parse(JSON.stringify(middle))
                    }
                })
                }else{
                this.changList.push(middle)
                }
                }else{
                this.changList.push(middle)
                }
                    // this.changList=tasks.data
                    this.flag=true
                    return true
                })
              
                
  
                // 拖动超过开始和结束日期
                gantt.attachEvent("onTaskDrag", function(id, mode, task, original){
                var min_date = gantt.getState().min_date;
                var max_date = gantt.getState().max_date;
                if (task.start_date.getTime()==min_date.getTime()) {
                gantt.config.start_date = min_date = gantt.date.add(min_date, -1, gantt.config.duration_unit);
                gantt.render();
                gantt.showDate(min_date);
                }
                if (task.end_date.getTime()==max_date.getTime()) {
                gantt.config.end_date = max_date = gantt.date.add(max_date, 1, gantt.config.duration_unit);
                gantt.render()
                }
                });
                if(this.state){
                var current_time = new Date(this.data.start_time);
                var today = gantt.addMarker({ 
                start_date: current_time, 
                css: "start_project", 
                text: 'Start',
                });
                       // 点击空白区域 移动标记
                gantt.attachEvent("onEmptyClick", (e)=>{
                if(gantt.getMarker(today)){
                let marker=gantt.getMarker(today)
                let mouseDate = gantt.dateFromPos(e.clientX+gantt.getScrollState().x -ganttdiv.getBoundingClientRect().left- gantt.config.grid_width)
                if (mouseDate) {
                   marker.start_date = mouseDate;
                   marker.text = gantt.date.date_to_str(gantt.config.date_format)(mouseDate);
                   this.currentDate=marker.text
                   this.$emit("change",`${this.currentDate}_${Math.random()}`)
                   gantt.renderMarkers();
                }
                }
                }); 
                }
                // 点击保存按钮之后修改数据
                gantt.attachEvent("onLightboxSave", (id, task, is_new)=>{
                     let arr={}
                    if(task.end_point){
                        this.data.results.forEach(item=>{
                        if(item.id==task.parent){
                        arr=item
                        arr.end_point_list[task.index]=JSON.parse(JSON.stringify(task))
                        arr.end_point_list[task.index].await_time=(task.end_date.getTime()-task.start_date.getTime())/1000/60
                        }
                    })
                    }else{
                        arr=task
                    }
                      arr.start_time=this.formateDate(arr.start_date)
                      arr.end_time=this.formateDate(arr.end_date)
                        editScheduler(arr).then(res => {
                        this.$toast({
                        title: this.$t('success'),
                        message:this.$t('flight.edit_success')
                        })
                    var min_date = gantt.getState().min_date;
                    var max_date = gantt.getState().max_date;
                    // 超出范围时，修改最大日期和最小日期
                    if (min_date >= +task.start_date) {
                    gantt.config.start_date = min_date = gantt.date.add(task.start_date, 0, gantt.config.duration_unit);
                    gantt.render()
                    }
                    if (max_date<= task.end_date) {
                    gantt.config.end_date = max_date = gantt.date.add(task.end_date, 0, gantt.config.duration_unit);
                    gantt.render()
                    }
                })
                return true
                })
            }
        },
        mounted() {
            this.layout = this.$route.meta.use_layout
            get_gantt_data({params:{type: this.$route.query.type,start_time: this.$route.query.start_time,end_time: this.$route.query.end_time
            }}).then(res => {
                this.data = res.data
                this.$nextTick(() => {
                    gantt.clearAll()
                    this.init();
                })
            })
        },
        watch:{
            '$i18n.locale'(newValue) {
                if (this.$i18n.locale === 'zh') {
                    gantt.i18n.setLocale(this.Chinese) 
                    gantt.render()
                } else if (this.$i18n.locale === 'en') {
                    gantt.i18n.setLocale(this.English) 
                    gantt.render()
                }
            },
            immediate:true
        } 
    }
</script>

<style lang="stylus" scoped>
    >>> #gantt

        .gantt_data_area, .gantt_task_cell, .gantt_task_scale, .gantt_grid_data, .gantt_row gantt_row_task, .gantt_row, .gantt_row_task, .gantt_grid_scale *
            background var(--v-secondary2-base)
        & *
            color var(--v-primary-base) 
            border-color var(--v-primary-base) 
        .gantt_marker 
            background rgba(0,255,0,0.5)
        .drag_handle
            background-image: radial-gradient(plum, orange,white);
            opacity: 0.2;
            width: 60px;
            height: 34px;
        .task_drag
            float:left;
            width: 100%;
            
</style>


